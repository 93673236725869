import { Col, Divider, Grid, Row } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const { useBreakpoint } = Grid;

export const Splash7 = () => {
  const { xs, sm, md } = useBreakpoint();
  const small = (xs || sm) && !md;
  return (
    <div
      className="wh-100v is-relative proper-background"
      style={{ backgroundImage: "url('/assets/images/splash/splash3.jpg')" }}
    >
      <div className="wrapper">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="container px-2">
            <Title
              level={small ? 3 : 2}
              className={`m-0 has-text-centered ${small ? "mb-1" : "mb-4"}`}
            >
              Variety of Meals you can choose from!
            </Title>
            <Row>
              <Col xs={24} md={8} className="p-3 is-flex is-align-items-center">
                <div
                  className="card p-3"
                  style={{
                    borderRadius: 8,
                    boxShadow: "3px 3px 10px 2px #0007",
                    width: "100%",
                    height: "100%",
                    background: "transparent",
                  }}
                >
                  <Title
                    level={small ? 5 : 3}
                    className="m-0 has-text-centered has-text-weight-bold"
                  >
                    1. Full Tiffine
                  </Title>
                  <div className="is-flex is-justify-content-center">
                    <div style={{ width: 75 }}>
                      <Divider
                        className={`m-0 ${small ? "my-1" : "mt-2 mb-4"}`}
                        style={{ backgroundColor: "#111" }}
                      />
                    </div>
                  </div>
                  <Title level={5} className="m-0 mb-1 px-3 has-text-centered">
                    1 Sabji, 1 Kathol, 5 Roti, Rice, Dal, Curd(if required)
                  </Title>
                  <Title
                    level={small ? 5 : 4}
                    className="m-0 mb-1 px-3 has-text-centered"
                  >
                    &#8377; 60
                  </Title>
                </div>
              </Col>
              <Col xs={24} md={8} className="p-3 is-flex is-align-items-center">
                <div
                  className="card p-3"
                  style={{
                    borderRadius: 8,
                    boxShadow: "3px 3px 10px 2px #0007",
                    width: "100%",
                    height: "100%",
                    background: "transparent",
                  }}
                >
                  <Title
                    level={small ? 5 : 3}
                    className="m-0 has-text-centered has-text-weight-bold"
                  >
                    2. Half Tiffine
                  </Title>
                  <div className="is-flex is-justify-content-center">
                    <div style={{ width: 75 }}>
                      <Divider
                        className={`m-0 ${small ? "my-1" : "mt-2 mb-4"}`}
                        style={{ backgroundColor: "#111" }}
                      />
                    </div>
                  </div>
                  <Title level={5} className="m-0 mb-1 px-3 has-text-centered">
                    1 Sabji, 1 Kathol, 6 Roti, Curd(if required)
                  </Title>
                  <Title
                    level={small ? 5 : 4}
                    className="m-0 mb-1 px-3 has-text-centered"
                  >
                    &#8377; 50
                  </Title>
                </div>
              </Col>
              <Col xs={24} md={8} className="p-3 is-flex is-align-items-center">
                <div
                  className="card p-3"
                  style={{
                    borderRadius: 8,
                    boxShadow: "3px 3px 10px 2px #0007",
                    width: "100%",
                    height: "100%",
                    background: "transparent",
                  }}
                >
                  <Title
                    level={small ? 5 : 3}
                    className="m-0 has-text-centered has-text-weight-bold"
                  >
                    3. Customised One!
                  </Title>
                  <div className="is-flex is-justify-content-center">
                    <div style={{ width: 75 }}>
                      <Divider
                        className={`m-0 ${small ? "my-1" : "mt-2 mb-4"}`}
                        style={{ backgroundColor: "#111" }}
                      />
                    </div>
                  </div>
                  <Title level={5} className="m-0 mb-1 px-3 has-text-centered">
                    Choose what you want, how much you want to serve, to how
                    many people, to make an event or a function special.
                  </Title>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
