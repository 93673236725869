import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

export const Splash2 = () => {
  return (
    <div
      className="wh-100v is-relative proper-background"
      style={{ backgroundImage: "url('/assets/images/splash/splash2.jpg')" }}
    >
      <div className="wrapper">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="w-100">
            <div className="container p-3">
              <Row>
                <Col
                  xs={24}
                  md={12}
                  className="is-flex is-justify-content-center is-align-items-center py-3"
                >
                  <div>
                    <Title level={1} className="font-kare m-0 mb-5">
                      Kaka Tiffine
                    </Title>
                    <Title
                      level={3}
                      className="m-0 mb-2 has-text-weight-normal"
                    >
                      Kaka Tiffine is meal delivering service located in
                      Ahmedabad.
                    </Title>
                    <Title level={3} className="m-0 has-text-weight-normal">
                      We are known to serve quality meal with on time delivery
                      to your specified location*.
                    </Title>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  className="is-flex is-justify-content-center is-align-items-center py-3"
                >
                  <div style={{ width: "70%" }}>
                    <figure className="image image-round-shadow">
                      <img
                        src="/assets/images/tiffin.png"
                        alt="404 - Not Found"
                      />
                    </figure>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
