import { Layout } from "antd";
import { Content, Footer } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { updateHeight, updateScroll, updateWidth } from "./actions/ui";
import { Header } from "./components/header/header";
import Home from "./components/home";
import URLS, { noFooters, noHeaderMargin, noHeaders } from "./constants/urls";

export const Main = () => {
  const path = useLocation().pathname;
  const isHeader = !noHeaders.includes(path);
  const isFooter = !noFooters.includes(path);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      dispatch(updateScroll());
    });
    window.addEventListener("resize", () => {
      dispatch(updateWidth());
      dispatch(updateHeight);
    });
  });

  const noMargin = noHeaderMargin.includes(path);
  const headerMargin = useSelector((state) => state.ui.headerSize);
  const footerMargin = useSelector((state) => state.ui.footerSize);

  return (
    <Layout>
      {isHeader && <Header />}
      <Content
        style={{
          marginTop: noMargin ? 0 : headerMargin,
          minHeight: `calc(100vh - ${headerMargin}px - ${footerMargin}px)`,
        }}
      >
        <Routes>
          <Route path={URLS.home} element={<Home />} />
        </Routes>
      </Content>
      {isFooter && <Footer />}
    </Layout>
  );
};
