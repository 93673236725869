import React from "react";

export const Splash1 = () => {
  return (
    <div
      className="wh-100v is-relative proper-background"
      style={{
        backgroundImage: 'url("/assets/images/splash/splash1.jpg")',
      }}
    >
      <div className="wrapper dark-tone">
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: "40%",
            left: 0,
            height: "20%",
            background: "#fff9",
          }}
          className="line-animation"
        ></div>
        <div
          style={{
            width: "100%",
            position: "absolute",
            top: "40%",
            left: 0,
            height: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <figure
            className="image logo-with-animation"
            style={{ width: "calc(100vh - 83vh)" }}
          >
            <img
              src="/assets/images/logo-with-name.png"
              alt="404 - Not Found"
            />
          </figure>
        </div>
      </div>
    </div>
  );
};
