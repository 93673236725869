import { Col, Grid, Row } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const { useBreakpoint } = Grid;

export const Splash4 = () => {
  const { xs, xm, md } = useBreakpoint();
  const small = (xs || xm) && !md;
  return (
    <div
      className="wh-100v is-relative proper-background"
      style={{ backgroundImage: "url('/assets/images/splash/splash2.jpg')" }}
    >
      <div className="wrapper">
        <div
          className="is-flex is-justify-content-center is-align-items-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container">
            <Row style={{ width: "100%" }}>
              <Col
                xs={24}
                md={8}
                className="is-flex is-justify-content-center is-align-items-center w-100"
              >
                <div className="has-text-centered">
                  <figure className="image is-flex is-justify-content-center is-align-items-center">
                    <img
                      src="/assets/images/star.png"
                      alt="404 - Not Found"
                      style={{ width: small ? 50 : 200, maxWidth: "100%" }}
                      className="mb-3"
                    />
                  </figure>
                  <Title level={small ? 5 : 3}>Professional Chef</Title>
                  <Title level={5} className="m-0">
                    Our Chefs have 15 years of experience in the cooking food of
                    different regions.
                  </Title>
                </div>
              </Col>
              <Col
                xs={24}
                md={8}
                className="is-flex is-justify-content-center is-align-items-center w-100"
              >
                <div className="has-text-centered">
                  <figure className="image is-flex is-justify-content-center is-align-items-center">
                    <img
                      src="/assets/images/star.png"
                      alt="404 - Not Found"
                      style={{ width: small ? 50 : 200, maxWidth: "100%" }}
                      className="mb-3"
                    />
                  </figure>
                  <Title level={small ? 5 : 3}>On-time Delivery</Title>
                  <Title level={5} className="m-0">
                    We deliver meal to your desired location on time specified
                    on the placement of order.
                  </Title>
                </div>
              </Col>
              <Col
                xs={24}
                md={8}
                className="is-flex is-justify-content-center is-align-items-center w-100"
              >
                <div className="has-text-centered">
                  <figure className="image is-flex is-justify-content-center is-align-items-center">
                    <img
                      src="/assets/images/star.png"
                      alt="404 - Not Found"
                      style={{ width: small ? 50 : 200, maxWidth: "100%" }}
                      className="mb-3"
                    />
                  </figure>
                  <Title level={small ? 5 : 3}>Rich Taste</Title>
                  <Title level={5} className="m-0">
                    We use hygienic masalas and fresh vegetables, which
                    maintains natural taste.
                  </Title>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
