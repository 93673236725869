import {
  EnvironmentOutlined,
  InstagramOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Grid, Row } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const { useBreakpoint } = Grid;

export const Splash9 = () => {
  const { xs, sm, md } = useBreakpoint();
  const small = (xs || sm) && !md;
  return (
    <div
      className="wh-100v is-relative proper-background"
      style={{ backgroundImage: "url('/assets/images/splash/splash3.jpg')" }}
    >
      <div className="wrapper">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="container p-3 mx-2"
            style={{
              background: `linear-gradient(${
                small ? "180deg" : "90deg"
              }, #fff 60%, #018038 60%)`,
              boxShadow: "3px 3px 10px 4px #0006",
              borderRadius: 8,
              height: "50%",
              position: "relative",
            }}
          >
            <img
              style={{ position: "absolute", height: "100%", top: 0, right: 0 }}
              src="/assets/images/contact-us-bg.png"
              alt="404 - Not Found"
            />
            <Row style={{ height: "100%" }}>
              <Col
                xs={24}
                md={14}
                className="is-flex is-align-items-center is-justify-content-center"
                style={{ height: "100%" }}
              >
                <div style={{ height: "fit-content" }}>
                  <Title level={3} className="has-text-centered m-0 mb-1">
                    Contact Us
                  </Title>
                  <div className="is-flex is-justify-content-center mb-5">
                    <div style={{ width: 75, background: "#404040" }}>
                      <Divider className="m-0" />
                    </div>
                  </div>
                  <div>
                    <Button
                      className="contact-us-button"
                      icon={
                        <PhoneOutlined
                          className="m-0"
                          style={{ transform: "rotateZ(90deg)" }}
                        />
                      }
                    >
                      9898803192
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="contact-us-button"
                      icon={<MailOutlined className="m-0" />}
                    >
                      kakastiffine@gmail.com
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="contact-us-button"
                      icon={<InstagramOutlined className="m-0" />}
                    >
                      kakastiffine
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="contact-us-button"
                      style={{ cursor: "text" }}
                      icon={<EnvironmentOutlined className="m-0" />}
                    >
                      Jivraj Park
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={10}></Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
