import { RightCircleFilled } from "@ant-design/icons";
import { Col, Divider, Grid, Row } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const { useBreakpoint } = Grid;

export const Splash3 = () => {
  const { xs, sm, md } = useBreakpoint();
  const smallScreen = (xs || sm) && !md;
  return (
    <div
      className="wh-100v is-relative proper-background"
      style={{ backgroundImage: "url('/assets/images/splash/splash3.jpg')" }}
    >
      <div className="wrapper">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="container px-2">
            <div
              className="card p-4 py-6 has-green-background-gradient"
              style={{ boxShadow: "3px 10px 15px 3px #2229", borderRadius: 8 }}
            >
              <Title
                level={smallScreen ? 3 : 2}
                className="has-text-white m-0 mb-1 has-text-centered"
              >
                About Food
              </Title>
              <div className="is-flex is-justify-content-center is-align-items-center">
                <div style={{ width: 100 }}>
                  <Divider className="has-background-white m-0 mt-2" />
                </div>
              </div>
              <Row className="m-0">
                <Col
                  xs={24}
                  md={12}
                  className="is-flex is-justify-content-center is-align-items-center"
                >
                  <figure className="iamge">
                    <img
                      src="/assets/images/dish.png"
                      alt="404 - Not Found"
                      style={{ width: smallScreen ? 250 : 400 }}
                    />
                  </figure>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  className="is-flex is-justify-content-center is-align-items-center"
                >
                  <div>
                    <div className="is-flex is-align-items-center">
                      <Title
                        level={smallScreen ? 5 : 4}
                        className="m-0 mr-4 has-text-white"
                      >
                        <RightCircleFilled />
                      </Title>
                      <Title
                        level={smallScreen ? 5 : 4}
                        className="has-text-weight-normal m-0 mb-2 has-text-white"
                      >
                        We serve quality food which includes Natural Ingredients
                        and Indian Masalas.
                      </Title>
                    </div>
                    <div className="is-flex is-align-items-center">
                      <Title
                        level={smallScreen ? 5 : 4}
                        className="m-0 mr-4 has-text-white"
                      >
                        <RightCircleFilled />
                      </Title>
                      <Title
                        level={smallScreen ? 5 : 4}
                        className="has-text-weight-normal m-0 mb-2 has-text-white"
                      >
                        We provide Gujarati, Kathiyavadi and Punjabi items in
                        suffling sequence in several days.
                      </Title>
                    </div>
                    <div className="is-flex is-align-items-center">
                      <Title
                        level={smallScreen ? 5 : 4}
                        className="m-0 mr-4 has-text-white"
                      >
                        <RightCircleFilled />
                      </Title>
                      <Title
                        level={smallScreen ? 5 : 4}
                        className="has-text-weight-normal m-0 mb-2 has-text-white"
                      >
                        We offer option of Roti, Paratha and Bajri Rotla as
                        well, with Rice and Dal in fixed menu.
                      </Title>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
