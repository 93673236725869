import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Splash1 } from "./splash1";
import { Splash2 } from "./splash2";
import { Splash3 } from "./splash3";
import { Splash4 } from "./splash4";
import { Splash5 } from "./splash5";
import { Splash6 } from "./splash6";
import { Splash7 } from "./splash7";
import { Splash8 } from "./splash8";
import { Splash9 } from "./splash9";

const Elements = [
  { Element: Splash1, extraProps: {} },
  { Element: Splash2, extraProps: {} },
  { Element: Splash3, extraProps: {} },
  { Element: Splash4, extraProps: {} },
  { Element: Splash5, extraProps: {} },
  { Element: Splash6, extraProps: {} },
  { Element: Splash7, extraProps: {} },
  { Element: Splash8, extraProps: {} },
  { Element: Splash9, extraProps: {} },
];

const scrollToElement = (index) => {
  window.scrollTo({
    top: index * window.innerHeight + 1,
    left: 0,
  });
};

const Home = () => {
  const [index, setIndex] = useState(0);
  const [showUpButton, setShowUpButton] = useState(false);
  const [showDownButton, setShowDownButton] = useState(true);
  const scroll = useSelector((state) => state.ui.scroll);
  const height = useSelector((state) => state.ui.height);

  useEffect(() => {
    scrollToElement(index);
    if (index > 0) {
      setShowUpButton(true);
    } else {
      setShowUpButton(false);
    }

    if (index < Elements.length - 1) {
      setShowDownButton(true);
    } else {
      setShowDownButton(false);
    }
  }, [index]);

  useEffect(() => {
    const division = scroll / height;
    if (division - Math.floor(division) === 0) {
      setIndex(Math.floor(division));
    } else if (division + 0.5 - Math.floor(division) === 0) {
      setIndex(Math.floor(division) + 1);
    }
  }, [scroll, height]);

  const onDownClick = () => {
    if (index < Elements.length - 1) {
      setIndex(index + 1);
    }
  };

  const onUpClick = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  return (
    <div>
      <FloatButton
        className={showUpButton ? "appear-button" : "disappear-button"}
        icon={<ArrowUpOutlined />}
        style={{ right: 20, top: 20 }}
        onClick={onUpClick}
      />
      <FloatButton
        className={showDownButton ? "appear-button" : "disappear-button"}
        icon={<ArrowDownOutlined />}
        style={{ right: 20 }}
        onClick={onDownClick}
      />
      {Elements.map((Element, key) => (
        <Element.Element {...Element.extraProps} key={key} />
      ))}
    </div>
  );
};

export default Home;
