import { Col, Divider, Grid, Row } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const { useBreakpoint } = Grid;

export const Splash8 = () => {
  const { xs, sm, md } = useBreakpoint();
  const small = (xs || sm) && !md;
  return (
    <div
      className="wh-100v is-relative proper-background"
      style={{ backgroundImage: "url('/assets/images/splash/splash2.jpg')" }}
    >
      <div className="wrapper">
        <div
          className="is-flex is-justify-content-center is-align-items-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container p-2">
            <Title level={small ? 3 : 2} className="has-text-centered m-0 mb-2">
              Meet Our Team
            </Title>
            <div className="is-flex is-justify-content-center mb-5">
              <div style={{ width: 100, background: "#404040" }}>
                <Divider className="m-0" />
              </div>
            </div>
            <Row>
              <Col xs={24} md={4}></Col>
              <Col xs={12} md={6} className="px-2">
                <div
                  className="card has-green-background-gradient p-2"
                  style={{
                    boxShadow: "3px 3px 10px 2px #0009",
                    borderRadius: 8,
                  }}
                >
                  <figure className="image">
                    <img
                      src="/assets/images/avatar.png"
                      alt="404 - Not Found"
                    />
                  </figure>
                  <Title
                    level={small ? 4 : 3}
                    className="has-text-centered m-0 mt-3 mb-0 has-text-white"
                  >
                    Rakesh Patel
                  </Title>
                  <Title
                    level={5}
                    className="has-text-centered m-0 mb-2 has-text-white"
                  >
                    Owner
                  </Title>
                </div>
              </Col>
              <Col xs={0} md={4}></Col>

              <Col xs={12} md={6} className="px-2">
                <div
                  className="card has-green-background-gradient p-2"
                  style={{
                    boxShadow: "3px 3px 10px 2px #0009",
                    borderRadius: 8,
                  }}
                >
                  <figure className="image">
                    <img
                      src="/assets/images/avatar.png"
                      alt="404 - Not Found"
                    />
                  </figure>
                  <Title
                    level={small ? 4 : 3}
                    className="has-text-centered m-0 mt-3 mb-0 has-text-white"
                  >
                    Bijal Patel
                  </Title>
                  <Title
                    level={5}
                    className="has-text-centered m-0 mb-2 has-text-white"
                  >
                    Head Chef
                  </Title>
                </div>
              </Col>
              <Col xs={24} md={4}></Col>
            </Row>
            <Title level={5} className="has-text-centered m-0 my-4">
              Generation by Generation, we have improved with help of
              technological advancement and man power, which tends to grow more
              and more, we are thankful to all of them who are associated with
              Kaka Tiffine and give their full potential to make service more
              wealthy.
            </Title>
          </div>
        </div>
      </div>
    </div>
  );
};
