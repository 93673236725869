const publicUrl = "/";

const URLS = {
  home: `${publicUrl}`,
};

export const noHeaders = [URLS.home];

export const noFooters = [URLS.home];

export const noHeaderMargin = [URLS.home];

export default URLS;
