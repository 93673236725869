import { Col, Grid, Row } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const { useBreakpoint } = Grid;

export const Splash5 = () => {
  const { xs, sm, md } = useBreakpoint();
  const small = (xs || sm) && !md;
  return (
    <div
      className="wh-100v is-relative proper-background"
      style={{ backgroundImage: "url('/assets/images/splash/splash3.jpg')" }}
    >
      <div className="wrapper">
        <div
          className="is-flex is-justify-content-center is-align-items-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container px-2">
            <Row style={{ width: "100%" }}>
              <Col
                xs={24}
                md={12}
                className="is-flex is-justify-content-center is-align-items-centers"
              >
                <figure className="image">
                  <img
                    src="/assets/images/packing.png"
                    alt="404 - Not Found"
                    style={{ width: small ? 200 : 400, maxWidth: "90%" }}
                  />
                </figure>
              </Col>
              <Col xs={24} md={12} className="is-flex is-align-items-center">
                <div>
                  <Title
                    level={4}
                    className={`m-0 ${
                      small ? "mb-1" : "mb-3"
                    } has-text-left has-text-weight-bold`}
                  >
                    We've been making The
                    <br />
                    Delicious Food Since 2001.
                  </Title>
                  <Title
                    level={5}
                    className="m-0 has-text-weight-semibold has-text-justified"
                  >
                    Kaka Tiffine started to give meal service in 2001 in Lalbhai
                    Dalpatbhai College of Engineering(LDCE) Hostels initiated by
                    my father, Late Shri. Jagdishchandra Patel. shockingly, he
                    passed away in 2016 but service continued by us. Nowadays,
                    we are providing service to offices, hostels and government
                    staff with our main places to delivery are SPIPA(Sardar
                    Patel Institute of Public Administration) and LDCE(L.D.
                    College of Engineering) Hostels.
                  </Title>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
