import { ui } from "../constants/redux";

export const updateWidth = () => ({ type: ui.width });

export const updateHeight = () => ({ type: ui.height });

export const updateScroll = () => ({ type: ui.scroll });

export const updateHeaderSize = (size) => ({ type: ui.headerSize, data: size });

export const updateFooterSize = (size) => ({ type: ui.footerSize, data: size });
