import React, { useLayoutEffect, useRef } from "react";
import { Header as AntHeader } from "antd/es/layout/layout";
import { useDispatch } from "react-redux";
import { updateHeaderSize } from "../../actions/ui";

export const Header = () => {
  const ref = useRef();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(updateHeaderSize(ref.current.clientHeight));
  });
  return <AntHeader ref={ref}></AntHeader>;
};
