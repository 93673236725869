import { Col, Divider, Grid, Row } from "antd";
import Title from "antd/es/typography/Title";
import React, { Fragment } from "react";

const { useBreakpoint } = Grid;

const data = [
  {
    key: "Sabji",
    value: "Punjabi, Gujarati, Kathiyavadi",
  },
  {
    key: "Roti",
    value: "Bajra Rotla, Paratha, Wheat Roti, Butter Roti, Naan",
  },
  {
    key: "Rice",
    value: "Jeera Rice, Plain Rice, Veg Pulao, Fried Rice",
  },
  {
    key: "Dal",
    value: "Dal fry, Toor dal, Dal Makhani, Gujarati Kadhi*",
  },
  {
    key: "Extras(extra charges may apply)",
    value: "Extra Roti, Curd, Buttermilk",
  },
  {
    key: "Special Items(Saturdays, spcial occasions - no extra cost)",
    value: "Gulab Jamun, Fruit Salad, Kheer, Dudhpaak, Savaiya",
  },
];

export const Splash6 = () => {
  const { xs, sm, md } = useBreakpoint();
  const small = (xs || sm) && !md;
  return (
    <div
      className="wh-100v is-relative proper-background"
      style={{ backgroundImage: "url('/assets/images/splash/splash2.jpg')" }}
    >
      <div className="wrapper">
        <div
          className="is-flex is-justify-content-center is-align-items-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container p-2">
            <div
              className={`card p-4 has-green-background-gradient ${
                small ? "p-1 py-2" : "p-2 py-6"
              }`}
              style={{ boxShadow: "3px 3px 10px 1px #0009" }}
            >
              <Row>
                <Col
                  xs={24}
                  md={12}
                  className="is-flex is-justify-content-center is-align-items-center p-2"
                >
                  <figure className="image">
                    <img
                      src="/assets/images/gujarati-dish.jpg"
                      alt="404 - Not Found"
                      style={{
                        borderRadius: 6,
                        boxShadow: "3px 3px 10px 2px #0009",
                        width: small ? 250 : 500,
                        maxWidth: "100%",
                      }}
                      className="mb-2"
                    />
                  </figure>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  className="is-flex is-align-items-center p-2"
                >
                  <div className="w-100">
                    <Title
                      level={small ? 4 : 3}
                      className="m-0 has-text-centered has-text-white"
                      style={
                        small
                          ? { fontSize: "15px" }
                          : md
                          ? { fontSize: "22px" }
                          : {}
                      }
                    >
                      What can be expected in Meal?
                    </Title>
                    <div className="is-flex is-justify-content-center">
                      <div style={{ width: 150 }}>
                        <Divider className="m-0 my-3 has-background-white" />
                      </div>
                    </div>
                    {data.map((item, key) => (
                      <Fragment key={key}>
                        <Title
                          level={small ? 5 : 4}
                          className={`m-0 has-text-white has-text-weight-bold ${
                            small ? "mb-0" : "mb-2"
                          }`}
                          style={small ? { fontSize: "12px" } : {}}
                        >
                          {item.key}
                        </Title>
                        <Title
                          level={5}
                          className="m-0 has-text-white has-text-weight-normal"
                          style={small ? { fontSize: "12px" } : {}}
                        >
                          {item.value}
                        </Title>
                        {key !== data.length - 1 && (
                          <div style={{ width: 100 }}>
                            <Divider className="m-0 my-3 has-background-white" />
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
