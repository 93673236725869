import { ui } from "../constants/redux";

const state = {
  width: window.innerWidth,
  height: window.innerHeight,
  scroll: window.scrollY,
  headerSize: 0,
  footerSize: 0,
};

export const uiReducer = (initialState = state, action) => {
  switch (action.type) {
    case ui.width:
      return { ...initialState, width: window.innerWidth };
    case ui.height:
      return { ...initialState, height: window.innerHeight };
    case ui.scroll:
      return { ...initialState, scroll: window.scrollY };
    case ui.headerSize:
      return { ...initialState, headerSize: action.data };
    case ui.footerSize:
      return { ...initialState, footerSize: action.data };
    default:
      return initialState;
  }
};
